h1, h2, h3, h4 {
    font-size: 28px;
    font-weight: bold;
}

p {
    font-size: 18px;
}

.register {
    background-color: #45ac8b;
}

.register h1 {
    color: #13825E;
}

.register p {
    color: #FFF;
}

.form-register {
   background-color: #FFF;
   border-radius: 20px;
}

.form-register .input-group-text {
    background-color: #13825E;
 }

.form-register p {
    color: #333;
    font-size: 12px;
}

.login {
    background-image: url(../images/mujeres-ciclistas-profesionales.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.login-mascotas {
    background-image: url(../images/gran-grupo-gatos-perros-mirando-camara-sobre-fondo-azul.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.login, .login-mascotas h1 {
    color: #fff;
}

.form-login {
    background-color: rgba(69, 172, 139, 0.8);
    border-radius: 5px;
}

.form-informacion-personal {
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    background-color: #f8f8f8;
}

.form-informacion-personal h1, h2 {
    font-size: 20px;
}

.card-header {
    color: #fff;
    background-color: #45ac8b;
}

.card-body {
    border-left: solid 1px #45ac8b;
    border-right: solid 1px #45ac8b;
    border-bottom: solid 1px #45ac8b;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.form-qr {
    background-color: #f5f5f5;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}

.form-qr-mascotas {
    background-color: #f5f5f5;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    margin-top: -50px;
}

.form-qr-mascotas p span {
    font-weight: lighter;
}

.rounded-image-container {
    width: 100px; 
    height: 100px;
    border-radius: 50%; 
    overflow: hidden; 
  }
  
.rounded-image {
    width: 100%;
    height: 100%;
}

.bg-primary {
    background-color: #13825E;
}
  

.form-qr-headings h1, h2 {
    font-size: 18px;
}

.form-qr p a{
    margin-bottom: 2px;
    color: #45ac8b;
    text-decoration: none;
}

.form-qr p span {
    font-weight: lighter;
}

.btn-tel {
    background-color: #0fc70f;
    color: #fff;
}

.btn-whatsapp {
    background-color: #25D366;
    color: #fff;
}

.btn-emergency {
    background-color: #FF0000;
    color: #fff;
    width: 100%;
}

.btn-info {
    background-color: #17a2b8;
    border: 1px solid #17a2b8;
    color: #fff;
}

.btn-tel:hover {
    border: 1px solid #0fc70f;
    color: #0fc70f;
}

.btn-whatsapp:hover {
    border: 1px solid #25D366;
    color: #25D366;
}

.btn-emergency:hover {
    border: 1px solid #FF0000;
    color: #FF0000;
}

.btn-info:hover {
    background-color: #fff;
    color: #17a2b8;
}

footer {
    background-color: #13825E;
    color: #FFF;
}

footer p {
    font-size: 14px;
    margin-bottom: 0;
}

footer p a {
    color: #fff;
    text-decoration: none;
    font-size: 14px;
}